import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import clsx from 'clsx';
import type { EmblaOptionsType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import type { ReactElement } from 'react';
import React, { Fragment, useCallback, useEffect } from 'react';

import styles from './index.module.scss';

interface CarouselProps {
	index?: number;
	showButtons?: boolean;
	slides: ReactElement[];
	options: Partial<EmblaOptionsType>;
	classNames?: {
		wrapper?: string;
		viewport?: string;
		container?: string;
		slide?: string;
	};
}

export const Carousel = ({
	index, //
	slides,
	options,
	classNames,
	showButtons = true,
}: CarouselProps): ReactElement => {
	const [emblaRef, emblaApi] = useEmblaCarousel(options);

	const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
	const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);

	useEffect(() => {
		emblaApi?.scrollTo(index || 0);
	}, [emblaApi, index]);

	return (
		<div className={classNames?.wrapper || styles.embla}>
			<div className={classNames?.viewport || styles.embla__viewport} ref={emblaRef}>
				<div className={classNames?.container || styles.embla__container}>
					{slides.map((slide, index) => (
						<div key={index} className={classNames?.slide || styles.embla__slide}>
							<Fragment>{slide}</Fragment>
						</div>
					))}
				</div>
			</div>
			{showButtons && (
				<div className="flex justify-center gap-x-3 mt-2">
					<button type="button" onClick={scrollPrev} title="Scroll Left">
						<IconChevronLeft className={clsx('w-8 h-8 lg:w-12 lg:h-12', styles.previousButton)} />
					</button>
					<button type="button" onClick={scrollNext} title="Scroll Right">
						<IconChevronRight className={clsx('w-8 h-8 lg:w-12 lg:h-12', styles.nextButton)} />
					</button>
				</div>
			)}
		</div>
	);
};
