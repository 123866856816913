import { FeaturedProducts } from '@src/modules/home/featured';
import { HeroImage } from '@src/modules/home/hero-image';
import { Layout } from '@src/modules/shared/components/layout';
import { SeoTags } from '@src/modules/shared/components/seo-tags';
import type { ReactElement } from 'react';

export default function HomePage(): ReactElement {
	return (
		<Layout>
			<SeoTags />
			<HeroImage />
			<div className="container py-12 font-medium">
				<p>
					As a family-owned company with over eight decades of experience in filtration, combined with the experience of
					our employees at over 80 sites worldwide and our innovative solutions, we can proudly say we are a global
					technology leader in filtration. Convinced by our vision of "Leadership in Filtration", we assume
					responsibility towards current and future generations and have successfully relied on continuity and
					reliability since our foundation in 1941.
				</p>
			</div>
			<FeaturedProducts />
		</Layout>
	);
}
